<template>
  <div 
    class="trends-material"
    :style="containerStyle"
  >
    <div 
      class="trends-material__image-container"
      :style="{ backgroundColor: bannerItem.trendBgColor }"
    >
      <!-- eslint-disable-next-line @shein-aidc/cccx/notUseImgTag -->
      <img 
        :src="bannerItem.titleIconImage?.src"
        :style="{ width: 'auto', height: '.4533rem' }"
      />
    </div>
    <div class="trends-material__word">
      <p 
        class="trends-material__word-text"
        :data-arrow="`${cssRight ? 'left' : 'right'}`"
      >{{ bannerItem.trendWord }}</p>
      <BImage
        :src="trendWordBackground"
        :ratio="9.82"
        :firstScreen="true"
        style="position: absolute;width: 2.88rem;height: .2933rem;"
        class="trends-material__word-background"
      />
    </div>
  </div>
</template>

<script setup>
import { Image as BImage } from '@shein/sui-mobile'


const props = defineProps({
  bannerItem: {
    type: Object,
    default: () => ({})
  },
  cssRight: {
    type: Boolean,
    default: false
  },
})

const trendWordBackground = props.cssRight ? 'https://img.ltwebstatic.com/images3_ccc/2025/01/15/78/1736912528d88e32b7dbd33361fa49acb2ca6ca453.webp' : 'https://img.ltwebstatic.com/images3_ccc/2025/01/09/a0/17364159335699ad2c086a9535a07a301ef46e69ea.webp'

const containerStyle = {
  ...props.cssRight ? { left: `${props.bannerItem.trendRightOffset / 37.5}rem` } : { right: `${props.bannerItem.trendRightOffset / 37.5}rem` },
}

</script>

<style lang="less">
.trends-material {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  display: flex;
  flex-direction: column;
  width: 5.68rem;

  &__image-container {
    align-self: flex-start/*rtl:flex-end*/;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: .6667rem;
    padding: .1067rem .2667rem;
    border-radius: .5333rem;
    margin-bottom: .2133rem;
  }

  &__word {
    display: inline-flex;
    position: relative;
    max-width: 5.0933rem;
    direction: ltr;
    align-self: flex-start/*rtl:flex-end*/;

    &-text {
      z-index: 4;
      color: #fff;
      word-break: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;
      font-size: .48rem;
      font-style: normal;
      font-weight: 700;
      max-width: 5.0933rem;
      line-height: .64rem;
      .line-camp(2);
    }
    &-text::after {
      content: '';
      display: inline-block;
      margin-left: .1067rem;
      white-space: nowrap;
      width: .32rem;
      height: .32rem;
      background: url('https://img.ltwebstatic.com/images3_ccc/2025/02/08/e0/1739004175e19ac69476380f8041df616ab6c35e06.webp') no-repeat center;
      background-size: contain;
    }
    &-text[data-arrow="left"]::after {
      transform: rotate(180deg);
    }
    &-text[data-arrow="right"]::after {
      transform: rotate(0deg);
    }
    &-icon {
      position: absolute;
    }
    &-background {
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
}
</style>
